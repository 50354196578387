import { useEffect } from "react";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import WMTS from "ol/source/WMTS";
import { getTopLeft } from "ol/extent";
import { ensureMap } from "@avinet/react-openlayers";

//*
// WMTSLayer for webmercator layers
//*
const WMTSLayer = ensureMap(
  ({ map, url, layerName, format, zIndex, minZoom, maxZoom, crossOrigin, transparent }) => {
    useEffect(() => {
      const grid_extent = [
        -20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244
      ];
      const resolutions = [
        156543.03392804097, 78271.51696402048, 39135.75848201024, 19567.87924100512,
        9783.93962050256, 4891.96981025128, 2445.98490512564, 1222.99245256282, 611.49622628141,
        305.748113140705, 152.8740565703525, 76.43702828517625, 38.21851414258813,
        19.109257071294063, 9.554628535647032, 4.777314267823516, 2.388657133911758,
        1.194328566955879, 0.5971642834779395, 0.29858214173896974
      ];
      const matrixIds = [];
      for (let z = 0; z < resolutions.length; ++z) {
        matrixIds[z] = z;
      }
      const tileSize = [256, 256];

      const source = new WMTS({
        url,
        layer: layerName,
        matrixSet: "webmercator",
        format: format || "png",
        projection: "EPSG:3857",
        transparent: transparent || true,
        crossOrigin: crossOrigin || "Anonymous",
        style: "",
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(grid_extent),
          resolutions: resolutions,
          matrixIds: matrixIds,
          tileSize: tileSize
        })
      });
      const layer = new TileLayer({ source, zIndex, minZoom, maxZoom });
      map.addLayer(layer);

      return () => {
        map.removeLayer(layer);
      };
    }, []);

    return null;
  }
);
export default WMTSLayer;
